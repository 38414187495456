export const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "home",
        name: "home",
        alias: "",
        props: true,
        component: () => import("../views/Home.vue"),
        children:[
          {
            path: "tuijian",
            name: "tuijian",
            alias: "",
            component: () => import("../views/主页/tuijian.vue"),
          },
          {
            path: "jingxuan",
            name: "jingxuan",
            component: () => import("../views/主页/jingxuan.vue"),
          },
          {
            path: "xinren",
            name: "xinren",
            component: () => import("../views/主页/xinren.vue"),
          },
          {
            path: "xinzuo",
            name: "xinzuo",
            component: () => import("../views/主页/xinzuo.vue"),
          },
        ]
      },
      {
        path: "shopcart",
        name: "shopcart",
        component: () => import("../views/Shopcart.vue"),
        children:[
          {
            path: "zhui",
            name: "zhui",
            alias: "",
            hidden: true,
            component: () => import("../views/zhui.vue"),
          },
          {
            path: "lis",
            name: "lis",
            component: () => import("../views/lis.vue"),
          },
        ]
      },
      {
        path: "my",
        name: "my",
        component: () => import("../views/My.vue"),
      },
    ],
  },

  {
    path: "/Ranking",
    name: "Ranking",
    component: () => import("../views/Ranking.vue"),
  },
  {
    path: "/Category",
    name: "Category",
    component: () => import("../views/Category.vue")
  },
  {
    path: "/Sousuo",
    name: "Sousuo",
    component: () => import("../views/Sousuo.vue"),
    children:[
      {
        path: "/moren",
        name: "moren",
        alias: "",
        component: () => import("../views/moren.vue"),
      }, {
        path: "/Liebiao/:id",
        name: "Liebiao",
        props:true,
        component: () => import("../views/Liebiao.vue")
      },
      {
        path: "/shisou",
        name: "shisou",
        props:true,
        component: () => import("../views/shisou.vue")
      },
    ]
  },
  {
    path: "/Denglu",
    name: "Denglu",
    component: () => import("../views/Denglu.vue")
  },
  {
    path: "/Zhuce",
    name: "Zhuce",
    component: () => import("../views/Zhuce.vue")
  },
  {
    path: "/Shezhi",
    name: "Shezhi",
    component: () => import("../views/Shezhi.vue")
  },
  {
    path: "/Xiangqing/:id",
    name: "Xiangqing",
    props:true,
    component: () => import("../views/Xiangqing.vue")
  },
  // {
  //   path: "/neirong/:id",
  //   name: "neirong",
  //   props:true,
  //   component: () => import("../views/neirong.vue")
  // },
  {
    path: "/neirong/:id/:title/:ming/:idse",
    name: "neirong",
    component: () => import("../views/neirong.vue"),
    props: route => ({
      id: route.params.id,
      title: route.params.title,
      ming: route.params.ming,
      idse: route.params.idse
    })
  }
];
