<template>
  <div id="app">

    <router-view/>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'app',
  data () {
    return {
     
    }
  },
  mounted(){
    $('#app').height($(window).height())
  }
}
</script>
<style lang="scss">

body{
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
