import Vue from 'vue'
import App from './App.vue'
import router from './router'

//导入flexible.js进行设置html标签的font-size
import 'lib-flexible/flexible';

import { Tabbar, TabbarItem, NavBar, Lazyload, Image } from "vant";

//全局注册组件
Vue.use(Tabbar).use(TabbarItem).use(NavBar).use(Lazyload).use(Image);
Vue.prototype.bus=new Vue()
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

