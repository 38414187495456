import Vue from 'vue'
import VueRouter from 'vue-router'

import {routes} from './routes'

import { Search } from 'vant';
import { PullRefresh } from 'vant';
import { Lazyload } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Icon } from 'vant';
import { ActionSheet } from 'vant';

import { Sidebar, SidebarItem } from 'vant';
import { Loading } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Switch } from 'vant';
import { Dialog } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';

import { DropdownMenu, DropdownItem } from 'vant';
import { ShareSheet } from 'vant';
import { Step, Steps } from 'vant';
import { Rate } from 'vant';
import { Divider } from 'vant';
import VueTouch from 'v-touch';

Vue.use(VueTouch);
Vue.use(Divider);
Vue.use(Rate);
Vue.use(Step);
Vue.use(Steps);
Vue.use(ShareSheet);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Form);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(Switch);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Loading);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(ActionSheet);
Vue.use(Icon);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(PullRefresh);
Vue.use(Search);
Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

Vue.use(Lazyload, {
  lazyComponent: true,
});
export default router
